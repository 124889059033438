import {
  ArrowLeftOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
  ArrowRightOutlined,
  InfoCircleOutlined
} from "@ant-design/icons";
import { CognitoUser } from "amazon-cognito-identity-js";
import Col from "antd/lib/col";
import message from "antd/lib/message";
import Row from "antd/lib/row";
import Space from "antd/lib/space";
import Checkbox, { CheckboxChangeEvent } from "antd/lib/checkbox";
import Form, { RuleObject } from "antd/lib/form";
import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  useLayoutEffect
} from "react";
import { useMutation, useQuery } from "react-query";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { createApiClient } from "apiClient";
import { AppButton } from "components/button";
import { ButtonType } from "components/button/appButton";
import { AppCard } from "components/card";
import { AppTextInput } from "components/inputs";
import { PasswordInput } from "components/inputs/textInput";
import { UserRoles } from "constants/roles";
import {
  CaregiverRoutesRef,
  CommonRoutesRef,
  PatientsRoutesRef,
  ProvidersRoutesRef
} from "constants/routes";
import { UserContext, UserContextState } from "contextApis/userContext";
import { Colors } from "helpers/colors";
import { CareGemIcon } from "./careGemIcon";
import { LoginBackgroundIcon } from "./loginbackground";
import { Auth } from "aws-amplify";
import { CredentialProps } from "sharedTypes";
import { EmailVerificationForm } from "./emailVerificationForm";
import { Skeleton, Spin } from "antd";
import Layout from "antd/lib/layout";
import { Tooltip } from "antd";
import { AppModal } from "components/modal";
import CaregemAgreementForm from "./CaregemAgreementForm";
import { overflow } from "styled-system";

const { Item } = Form;

const EyeTwoToneStyled = styled(EyeTwoTone)``;

interface LoginPageProps {
  readonly isAmplifyConfiguring: boolean;
}

const LoginPage: React.FC<LoginPageProps> = ({ isAmplifyConfiguring }) => {
  const [searchParams] = useSearchParams();
  const [tokenavail, setTokenAvail] = useState(false);
  const consent_token = searchParams.get("consent_token") || "";

  const consent_username = searchParams.get("username") || "";

  const [checkPAF, setPAF] = useState<boolean>(false);
  const [checkETP, setETP] = useState<boolean>(false);
  const [consents, setConsents] = useState<any>([]);
  const [organizationLogo, setOrganizationLogo] = useState<any>("");

  const {
    isLoading: consentLoading,
    data: consentData,
    error: consentDataError
  } = useQuery("user-consent-data", async () => {
    return await createApiClient().getConsent(consent_token);
  });

  useLayoutEffect(() => {
    const checkSigned = () => {
      const Consents = consentData?.consents?.filter(
        (signed: any) => signed.sign_date === null
      );
      console.log(Consents);
      setConsents(Consents);
    };
    checkSigned();
  }, [consentData]);

  useEffect(() => {
    // if (current_route !== "Login") {
    if (isLoading == false && consents?.length === 0) {
      userContext.setFromConsentFlag(true);
    }
    // }
  }, [consents]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const userContext = useContext<UserContextState>(UserContext);
  const urlParamsRef = useRef({ username: "", code: "", action: "" });
  const confirmClicked = useRef<boolean>();

  const [isResetLoading, setIsResetLoading] = useState<boolean>(false);

  const [username, setUsername] = useState<string>(
    sessionStorage.getItem("login_username") || ""
  );

  const [clientLogoCaregem, setClientLogoCaregem] = useState(
    process.env["REACT_APP_ASSET_ENDPOINT"] + "logo/6/logo.png"
  );
  const [practiceLogoCaregem, setPracticeLogoCaregem] = useState(
    process.env["REACT_APP_ASSET_ENDPOINT"] + "logo/CareGemHealthLogo.png"
  );

  const onInputChange = (inputValue: string) => setUsername(inputValue);

  const [password, setPassword] = useState<string>("");
  const [iAgreeChecked, setIAgreeChecked] = useState(true);
  const onPasswordInput = (inputValue: string) => setPassword(inputValue);
  const [remember, setRemember] = useState<boolean>(
    sessionStorage.getItem("login_remember") === "true" || false
  );
  const [isNewPasswordRequired, setIsNewPasswordRequired] = useState(false);
  const [isVerifyEmailRequired, setIsVerifyEmailRequired] = useState(false);
  const [cognitoUser, setCognitoUser] = useState<CognitoUser>();
  const onRememberInput = (e: CheckboxChangeEvent) =>
    setRemember(e.target.checked);
  const onIAgreeChecked = (e: CheckboxChangeEvent) => {
    setIAgreeChecked(e.target.checked);
  };

  useEffect(() => {
    if (consentData) {
      setClientLogoCaregem(
        process.env["REACT_APP_ASSET_ENDPOINT"] + consentData?.org_details?.logo
      );
      setIsLoading(false);
    }
    // else {
    //   setClientLogoCaregem(
    //     process.env["REACT_APP_ASSET_ENDPOINT"] + "logo/6/logo.png"
    //   );
    // }
  }, [consentData]);

  // console.log(consentData);

  const PAFInput = (e: CheckboxChangeEvent) => setPAF(e.target.checked);
  const ETPInput = (e: CheckboxChangeEvent) => setETP(e.target.checked);

  const GetStartedClick = () => {
    form
      .validateFields()
      .then(values => {
        onFinish({
          username: consent_username || "",
          password: form.getFieldValue("newPassword"),
          remember: false
        });
      })

      .catch(error => {
        console.error("Validation error:", error);
      });
  };

  const [PleaseEnterPassword, setPleaseEnterPassword] = useState(false);
  const [PasswordsDoNotMatch, setPasswordsDoNotMatch] = useState(false);
  const [Longest, setLongest] = useState(false);
  const [Long, setLong] = useState(false);

  const validateConfirmPassword = (
    rules: RuleObject,
    value: string,
    callback: (error?: string) => void
  ) => {
    if (form.getFieldValue("confirmNewPassword") === "") {
      setPleaseEnterPassword(true);
      // callback("Please enter password");
    }
    if (
      form.getFieldValue("newPassword") !==
      form.getFieldValue("confirmNewPassword")
    ) {
      setPasswordsDoNotMatch(true);
      // callback("Passwords do not match.");
    } else {
      setPleaseEnterPassword(false);
      setPasswordsDoNotMatch(false);
      setLongest(false);
      setLong(false);
      callback();
    }
  };

  // useEffect(() => {
  //   setPasswordsDoNotMatch(false);
  // }, [validateConfirmPassword]);

  const passwordRegEx =
    /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,25}$/;

  const validatePassword = (
    rules: RuleObject,
    value: string,
    callback: (error?: string) => void
  ) => {
    if (value && !value.match(passwordRegEx)) {
      setLongest(true);
      // callback(
      //   "Password should contain: Between 8 and 25 characters including one capital character, one lowercase character, one special character and one numeric character."
      // );
    } else {
      setPleaseEnterPassword(false);
      setPasswordsDoNotMatch(false);
      setLongest(false);
      setLong(false);
      callback();
    }
  };

  const validatePasswordLength = (
    rules: RuleObject,
    value: string,
    callback: (error?: string) => void
  ) => {
    if (value && (value.length < 8 || value.length > 25)) {
      setLong(true);
      // callback("Password length should be between 8 and 25 characters");
    } else {
      setPleaseEnterPassword(false);
      setPasswordsDoNotMatch(false);
      setLongest(false);
      setLong(false);
      callback();
    }
  };

  const [form] = Form.useForm();

  const navigate = useNavigate();

  const { mutate: getUserData, isLoading: isRefetching } = useMutation(
    "userData",
    async (accessToken: string) => {
      return await createApiClient(accessToken).getLoggedInUserData();
    },
    {
      onSuccess: data => {
        //SuccessFull Login Mail will call here on successsfull login response recieve

        const consent_token = searchParams.get("consent_token");
        consent_token && createApiClient().getValidateLoginMail(consent_token);

        sessionStorage.setItem("userRole", data.role);
        sessionStorage.setItem("billingPermission", data.billing_permission);
        sessionStorage.setItem("userId", data.internal_id);
        sessionStorage.setItem("userName", data.userName);
        setIsLoading(false);

        switch (data.role) {
          case UserRoles.PATIENT:
            userContext.onLogin();
            navigate(PatientsRoutesRef.Symptoms);
            return;
          case UserRoles.CAREGIVER:
            userContext.onLogin();
            navigate(CaregiverRoutesRef.Patients);
            return;
          case UserRoles.CUSTOMER_ADMIN:
            message.warning(
              "You are not authorized to access this application."
            );
            sessionStorage.clear();
            return;
          case UserRoles.SUPER_ADMIN:
            message.warning(
              "You are not authorized to access this application."
            );
            sessionStorage.clear();
            return;
          default:
            userContext.onLogin();
            navigate(ProvidersRoutesRef.Patients);
        }
      },
      onError: () => {
        userContext.onLogout();
        setIsLoading(false);
        message.error("Failed to fetch user information");
      }
    }
  );

  const isCodeNum = (value: string) => {
    return /^\d+$/.test(value);
  };

  const [forgetPatient, setForgetPatient] = useState(false);

  const [orgId, setOrgId] = useState<any>();

  useEffect(() => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const code = params.get("code");
    const role = params.get("role");
    const token = params.get("consent_token");
    const action = searchParams.get("action") || "";
    const organization_id = searchParams.get("organization_id");
    organization_id && setOrgId(organization_id);

    if (code && code.endsWith("@") && !action) {
      userContext.setFromConsentFlag(true);
    }
    if (token && role) {
      userContext.setFromConsentFlag(false);
      setForgetPatient(true);
    }
    if (token && !role) {
      setTokenAvail(true);
    } else {
      userContext.setFromConsentFlag(false);
    }
  }, [userContext.fromConsent]);

  useEffect(() => {
    const username = searchParams.get("username") || "";
    const code = searchParams.get("code") || "";
    const action = searchParams.get("action") || "";
    if (username && code && !action) {
      setIsResetLoading(true);
    }
    console.log("userContext.fromConsent", userContext.fromConsent);
    if (username && code) {
      urlParamsRef.current.username = username;
      urlParamsRef.current.code = code;
      if (userContext.fromConsent) {
        setIsNewPasswordRequired(true);
        setIAgreeChecked(true);
      }
    }

    if (action) {
      urlParamsRef.current.action = action;
    }
  }, [searchParams, userContext.fromConsent]);

  useEffect(() => {
    setTimeout(() => {
      if (isNewPasswordRequired === true) {
        setIsResetLoading(false);
      }
    }, 2000);
  }, [isNewPasswordRequired]);

  useEffect(() => {
    const isEmailValidation = urlParamsRef.current.action === "email_change";

    if (
      urlParamsRef.current.username &&
      urlParamsRef.current.code &&
      !isAmplifyConfiguring
    ) {
      if (isEmailValidation) {
        console.log("isEmailValidationInside", isEmailValidation);
        setIsLoading(false);
        setIsVerifyEmailRequired(true);
      } else if (isCodeNum(urlParamsRef.current.code)) {
        setIsNewPasswordRequired(true);
      } else {
        console.log(
          "urlParamsRef.current.username",
          urlParamsRef.current.username
        );
        console.log("urlParamsRef.current.code", urlParamsRef.current.code);
        // amplifyLogin({
        //   username: urlParamsRef.current.username || "",
        //   password: urlParamsRef.current.code || "",
        //   remember: false
        // });
        setIsLoading(false);
      }
    }
  }, [
    urlParamsRef.current.username,
    urlParamsRef.current.code,
    isAmplifyConfiguring
  ]);

  function hasWhiteSpace(s: string) {
    return /\s/g.test(s);
  }

  const onFinish = (values: CredentialProps) => {
    //logInUser(values);

    const tempValues = { ...values };

    tempValues.username = values.username.trim();
    tempValues.password = values.password.trim();

    if (
      hasWhiteSpace(tempValues.username) ||
      hasWhiteSpace(tempValues.password)
    ) {
      message.error("Username or password is incorrect.");
      return;
    }

    console.log(tempValues);

    amplifyLogin(tempValues);
    setIsLoading(true);
  };

  const updateUserAttributes = async (userId: string) => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, {
        profile: userId
      });
    } catch (err) {
      console.log(err);
    }
  };

  const [ConfirmPasswordValidation, setConfirmPasswordValidation] = useState();

  const onChangeNewPassword = (event: any) => {
    setConfirmPasswordValidation(event);
    if (confirmClicked.current) {
      form.validateFields();
    }
  };

  useEffect(() => {
    setPasswordsDoNotMatch(false);
    setLongest(true);
  }, [ConfirmPasswordValidation]);

  const onClickConfirm = () => {
    amplifyLogin({
      username: urlParamsRef.current.username || "",
      password: urlParamsRef.current.code || "",
      remember: false
    });

    confirmClicked.current = true;
  };

  const getAccessTokenAndUserData = (values: CredentialProps) => {
    Auth.currentUserInfo()
      .then(curUser => {
        if (curUser.attributes?.profile === "none") {
          updateUserAttributes(curUser.id);
          Auth.signIn({
            username: values.username,
            password: values.password
          }).then(() => {
            Auth.currentSession().then(data => {
              const accessToken = data.getAccessToken().getJwtToken();
              sessionStorage.setItem("userIdentityId", curUser.id);
              userContext.onUserIdentityIdChange(curUser.id);
              getUserData(accessToken);
              sessionStorage.setItem("access_token", accessToken);
            });
          });
        } else {
          Auth.currentSession().then(data => {
            const accessToken = data.getAccessToken().getJwtToken();
            sessionStorage.setItem("userIdentityId", curUser.id);
            userContext.onUserIdentityIdChange(curUser.id);
            getUserData(accessToken);
            sessionStorage.setItem("access_token", accessToken);
          });
        }
      })
      .catch(err => {
        console.log(`Failed to set authenticated user! ${err}`);
      });
  };

  console.log(cognitoUser);

  const amplifyLogin = (values: CredentialProps) => {
    if (values.remember) {
      sessionStorage.setItem("login_remember", "true");
      sessionStorage.setItem("login_username", values.username);
    }

    if (!values.remember) {
      sessionStorage.setItem("login_remember", "false");
      sessionStorage.setItem("login_username", "");
    }

    console.log("cognitoUser", cognitoUser);

    if (isVerifyEmailRequired) {
      Auth.signIn({
        username: urlParamsRef.current.username,
        password: values.password
      })
        .then(user => {
          Auth.verifyUserAttributeSubmit(
            user,
            "email",
            urlParamsRef.current.code
          ).then(() => {
            getAccessTokenAndUserData({
              username: urlParamsRef.current.username,
              password: form.getFieldValue("verify_email_password"),
              remember: false
            });
          });
        })
        .catch(e => {
          console.log("e.message", e.message);
          message.error(e.message);
          navigate("/");
          setIsLoading(false);
        });
    } else if (isNewPasswordRequired) {
      if (isCodeNum(urlParamsRef.current.code)) {
        Auth.forgotPasswordSubmit(
          urlParamsRef.current.username,
          urlParamsRef.current.code,
          values.password
        )
          .then((data: any) => {
            console.log("data", data);
            Auth.signIn({
              username: urlParamsRef.current.username,
              password: values.password
            }).then(() => {
              getAccessTokenAndUserData({
                username: urlParamsRef.current.username,
                password: values.password,
                remember: false
              });
            });
          })
          .catch(e => {
            console.log("error", e);
            if (
              e.message ===
              "Invalid code provided, please request a code again."
            ) {
              message.error({
                type: "error",
                content: "Code had been expired. Please request code again.",
                duration: 10
              });
            } else {
              if (
                e.message ===
                "Password does not conform to policy: Password not long enough"
              )
                return;
              message.error(e.message);
            }
            setIsLoading(false);
          });
      } else {
        Auth.completeNewPassword(cognitoUser, values.password, {
          "custom:agreement": "1",
          "custom:messages": "1"
        })
          .then(() => {
            getAccessTokenAndUserData(values);
          })
          .catch(e => {
            console.log(e);
          });
      }
    } else {
      Auth.signIn({
        username: values.username,
        password: values.password
      })
        .then((user: CognitoUser) => {
          if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
            setIsLoading(false);
            setIsNewPasswordRequired(true);
          } else {
            getAccessTokenAndUserData(values);
          }
          setCognitoUser(user);
        })
        .catch(err => {
          // if (urlParamsRef.current.code) {
          //   message.error({
          //     type: "error",
          //     content:
          //       "You have used an invalid link for signing up in CareGem. Please connect with your practice and ask them to send you a new invite for signing up.",
          //     duration: 10
          //   });
          // } else {
          // message.error(err.message);
          // }
          if (urlParamsRef.current.code) {
            if (err.message === "Incorrect username or password.") {
              message.error({
                type: "error",
                content: "link has been expired.",
                duration: 10
              });
              navigate("/");
              window.location.reload();
            }
          } else {
            message.error(err.message);
          }
          setIsLoading(false);
        });
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const onNewPasswordBackClick = () => {
    setIsNewPasswordRequired(false);
    setIsVerifyEmailRequired(false);
    urlParamsRef.current = { username: "", code: "", action: "" };
    setUsername("");
    setPassword("");
  };

  const showDisclaimer =
    !isCodeNum(urlParamsRef.current.code) &&
    isNewPasswordRequired &&
    !isVerifyEmailRequired;

  const disableNewPasswordInput = !iAgreeChecked && showDisclaimer;

  const [CSAView, setCSAView] = useState(false);

  const handleCSA = () => {
    setCSAView(!CSAView);
  };

  useEffect(() => {
    if (!isCodeNum(urlParamsRef.current.code)) {
      if (urlParamsRef.current.username && urlParamsRef.current.code) {
        amplifyLogin({
          username: urlParamsRef.current.username || "",
          password: urlParamsRef.current.code || "",
          remember: false
        });
      }
    }
  }, [urlParamsRef.current.username, urlParamsRef.current.code]);

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const onValuesChange = (changedValues: any, allValues: any) => {
    if (allValues.newPassword === allValues.confirmNewPassword) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  };

  useEffect(() => {
    setOrganizationLogo(
      `${process.env["REACT_APP_ASSET_ENDPOINT"]}logo/${orgId}/organisation_logo.jpg`
    );
  }, [orgId]);

  return (
    <LoginBackground>
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "grid",
          placeItems: "center",
          overflow: "hidden"
        }}
      >
        <LoginBackgroundIcon />
      </div>

      <AppCard
        cardHeight={
          showDisclaimer
            ? "600px"
            : isNewPasswordRequired || isVerifyEmailRequired
            ? "470px"
            : "555px"
        }
        cardWidth={showDisclaimer ? "580px" : "380px"}
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxWidth: showDisclaimer ? "580px" : "380px",
          width: "90vw"
        }}
      >
        {" "}
        {isResetLoading ? (
          <>
            <div
              style={{
                width: "100%",
                height: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "30%"
              }}
            >
              <Spin size="large" style={{ alignSelf: "center" }} />
            </div>
          </>
        ) : (
          <>
            {isVerifyEmailRequired && (
              <BackArrowContainer onClick={onNewPasswordBackClick}>
                <ArrowLeftOutlined />
              </BackArrowContainer>
            )}
            <Row
              gutter={[0, 40]}
              style={{ marginTop: showDisclaimer ? "2%" : "10%" }}
            >
              {isVerifyEmailRequired ? (
                <Col
                  span={24}
                  style={{
                    textAlign: "center",
                    marginTop: "-5%"
                  }}
                >
                  <CareGemIcon logoWidth={44} logoHeight={44} />
                  <div style={{ fontSize: "18px" }} color={Colors.Black}>
                    <b>CareGem</b>
                  </div>

                  <div
                    style={{
                      fontSize: "12px",
                      fontWeight: "bolder"
                    }}
                  >
                    <b>Email Verification</b>
                  </div>

                  <EmailVerificationForm
                    form={form}
                    onFinish={onFinish}
                    cognitoUser={cognitoUser}
                    onFinishFailed={onFinishFailed}
                    isLoading={isLoading}
                    isRefetching={isRefetching}
                    onClickConfirm={onClickConfirm}
                    urlParamsRef={urlParamsRef}
                  />
                </Col>
              ) : isNewPasswordRequired ? (
                <>
                  <>
                    <>
                      {/* SET NEW PASSWORD */}
                      {!showDisclaimer && !userContext.fromConsent && (
                        <Col span={24} style={{ textAlign: "center" }}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                              alignContent: "center",
                              marginBottom: 10
                            }}
                          ></div>
                          <span>
                            {showDisclaimer && userContext.fromConsent && (
                              <CareGemIcon logoWidth={20} logoHeight={20} />
                            )}
                          </span>

                          {!showDisclaimer && !userContext.fromConsent && (
                            <span
                              style={{
                                position: "absolute",
                                transform: "translate(-50%, -50%)"
                              }}
                            >
                              <CareGemIcon logoWidth={54} logoHeight={54} />
                              <div
                                style={{ fontSize: "18px" }}
                                color={Colors.Black}
                              >
                                <b>CareGem</b>
                              </div>
                            </span>
                          )}

                          <div
                            style={{
                              fontSize: "12px",
                              fontWeight: "bolder",
                              marginTop: 54
                            }}
                          >
                            <b>Set New Password</b>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                              alignContent: "center",
                              marginBottom: 17,
                              marginTop: 29
                            }}
                          >
                            <AppTextInput
                              style={{
                                width: "290px",
                                borderRadius: "9px",
                                placeItems: "center"
                              }}
                              value={consent_username}
                              label="Username"
                              labelStyles={{ zIndex: 1000 }}
                              textStyles={{ height: "46px" }}
                            />
                          </div>
                          <FormWrapper
                            setNewPassword={isNewPasswordRequired}
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                              alignContent: "center",
                              marginBottom: 10,
                              paddingTop: "17px"
                            }}
                          >
                            <Form
                              onValuesChange={onValuesChange}
                              name="new password form"
                              form={form}
                              onFinish={() => {
                                if (
                                  form.getFieldValue("newPassword") ===
                                  form.getFieldValue("confirmNewPassword")
                                ) {
                                  onFinish({
                                    username: cognitoUser?.getUsername() || "",
                                    password: form.getFieldValue("newPassword"),
                                    remember: false
                                  });
                                }
                              }}
                              onFinishFailed={onFinishFailed}
                              style={{ textAlign: "left" }}
                            >
                              <Item
                                name="newPassword"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter your new password."
                                  },
                                  {
                                    validator: validatePassword,
                                    validateTrigger: "submit"
                                  }
                                ]}
                                // className="new-password"
                                style={{
                                  position: "absolute",
                                  marginBottom: 10
                                }}
                              >
                                <PasswordInput
                                  style={{
                                    width: "290px",
                                    borderRadius: "9px",
                                    placeItems: "center"
                                  }}
                                  value={""}
                                  label="Password"
                                  placeholder="Password"
                                  onChange={onChangeNewPassword}
                                  labelStyles={{ zIndex: 1000 }}
                                  iconRender={visible =>
                                    visible ? (
                                      <EyeTwoToneStyled />
                                    ) : (
                                      <EyeInvisibleOutlined />
                                    )
                                  }
                                  textStyles={{ height: "46px" }}
                                  disabled={disableNewPasswordInput}
                                />
                              </Item>

                              {/* confirmNewPassword */}
                              <Item
                                name="confirmNewPassword"
                                rules={[
                                  {
                                    validateTrigger: "submit",
                                    validator: validateConfirmPassword
                                  }
                                ]}
                                style={{
                                  marginTop: "70px",
                                  display: "flex",
                                  justifyContent: "space-around",
                                  alignContent: "center",
                                  marginBottom: 17
                                }}
                              >
                                <span
                                  style={{
                                    display: "flex"
                                  }}
                                >
                                  <Item
                                    name="confirmNewPassword"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please enter new password."
                                      },
                                      {
                                        validateTrigger: "submit",
                                        validator: validateConfirmPassword
                                      }
                                    ]}
                                  >
                                    <PasswordInput
                                      style={{
                                        width: "290px",
                                        borderRadius: "8px",
                                        placeItems: "center"
                                      }}
                                      value={""}
                                      label="Confirm New Password"
                                      placeholder="Confirm New Password"
                                      onChange={onChangeNewPassword}
                                      labelStyles={{ zIndex: 1000 }}
                                      iconRender={visible =>
                                        visible ? (
                                          <EyeTwoToneStyled />
                                        ) : (
                                          <EyeInvisibleOutlined />
                                        )
                                      }
                                      textStyles={{ height: "46px" }}
                                      disabled={disableNewPasswordInput}
                                    />
                                  </Item>
                                </span>
                              </Item>
                              <Form.Item
                                wrapperCol={{ offset: 0, span: 10 }}
                                style={{
                                  display: "flex",
                                  justifyContent: "space-around",
                                  alignContent: "center",
                                  marginTop: 29
                                }}
                              >
                                <AppButton
                                  type={ButtonType.Primary}
                                  htmlType="submit"
                                  loading={isLoading || isRefetching}
                                  buttonContent={
                                    <Space size={4}>Confirm</Space>
                                  }
                                  style={{
                                    width: "240px",
                                    marginBottom: "-40%"
                                  }}
                                  onClick={onClickConfirm}
                                  disabled={isButtonDisabled}
                                />
                              </Form.Item>
                            </Form>
                          </FormWrapper>
                        </Col>
                      )}
                    </>
                    {!tokenavail ? (
                      <>
                        {/* FOR PROVIDERS WELCOME SCREEN */}
                        {showDisclaimer && !userContext.fromConsent && (
                          <>
                            {isLoading ? (
                              <>
                                <div
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                  }}
                                >
                                  <Spin
                                    tip="Loading"
                                    size="large"
                                    style={{
                                      alignSelf: "center",
                                      marginTop: "45%"
                                    }}
                                  ></Spin>
                                </div>
                              </>
                            ) : (
                              <>
                                <AppCard
                                  cardHeight={"614px"}
                                  cardWidth={"1016px"}
                                  style={{
                                    position: "fixed",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)"
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between"
                                    }}
                                  >
                                    <img
                                      className="logo-left"
                                      src={organizationLogo}
                                      alt={organizationLogo}
                                      width={300}
                                    />
                                    <img
                                      className="logo-right"
                                      src={practiceLogoCaregem}
                                      alt="CareGemHealthLogo"
                                      width={180}
                                    />
                                  </div>

                                  <div
                                    style={{
                                      display: "flex",
                                      fontSize: 24,
                                      fontWeight: "bold",
                                      marginTop: 29,
                                      alignContent: "center",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      justifyItems: "center"
                                    }}
                                  >
                                    Welcome to CareGem.
                                  </div>

                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      marginTop: 29
                                    }}
                                  >
                                    <AppTextInput
                                      style={{
                                        width: "290px",
                                        borderRadius: "9px",
                                        placeItems: "center"
                                      }}
                                      value={consent_username}
                                      label="Username"
                                      labelStyles={{ zIndex: 1000 }}
                                      textStyles={{ height: "46px" }}
                                    />
                                  </div>

                                  <div
                                    style={{
                                      fontSize: 18,
                                      fontWeight: "bold",
                                      marginTop: 34
                                    }}
                                  >
                                    Set your password here to get started
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      marginTop: 29
                                    }}
                                  >
                                    <FormWrapper>
                                      <Form
                                        name="new password form"
                                        form={form}
                                        style={{
                                          textAlign: "left",
                                          display: "flex",
                                          justifyContent: "space-between"
                                        }}
                                        onFinish={() => {
                                          form.validateFields();
                                        }}
                                        onFinishFailed={onFinishFailed}
                                      >
                                        <Item
                                          name="newPassword"
                                          rules={[
                                            {
                                              required: false,
                                              message:
                                                "Please enter your new password."
                                            },
                                            {
                                              validator: validatePassword,
                                              validateTrigger: "submit"
                                            }
                                          ]}
                                          className="new-password"
                                        >
                                          <PasswordInput
                                            style={{
                                              width: "290px",
                                              borderRadius: "9px",
                                              placeItems: "center"
                                            }}
                                            value={""}
                                            label="Password"
                                            placeholder="Password"
                                            onChange={onChangeNewPassword}
                                            labelStyles={{ zIndex: 1000 }}
                                            iconRender={visible =>
                                              visible ? (
                                                <EyeTwoToneStyled />
                                              ) : (
                                                <EyeInvisibleOutlined />
                                              )
                                            }
                                            textStyles={{ height: "46px" }}
                                          />
                                        </Item>
                                        <Item
                                          style={{
                                            marginLeft: 8,
                                            marginRight: 10.5,
                                            marginTop: 7.5
                                          }}
                                        >
                                          <Tooltip
                                            title={
                                              <ul
                                                style={{
                                                  margin: 0,
                                                  paddingInlineStart: "20px"
                                                }}
                                              >
                                                Password should contain:
                                                <li>
                                                  Between 8 and 25 characters
                                                </li>
                                                <li>
                                                  At least one capital letter
                                                </li>
                                                <li>
                                                  At least one lowercase letter
                                                </li>
                                                <li>
                                                  At least one special character
                                                </li>
                                                <li>
                                                  At least one numeric digit
                                                </li>
                                              </ul>
                                            }
                                          >
                                            <InfoCircleOutlined
                                              style={{
                                                color: "gray",
                                                fontSize: 17
                                              }}
                                            />
                                          </Tooltip>
                                        </Item>

                                        <Item
                                          name="confirmNewPassword"
                                          style={{
                                            marginLeft: 23.5
                                          }}
                                          rules={[
                                            {
                                              validateTrigger: "submit",
                                              validator: validateConfirmPassword
                                            }
                                          ]}
                                          validateStatus={
                                            PasswordsDoNotMatch
                                              ? "error"
                                              : !Longest
                                              ? "error"
                                              : "success"
                                          }
                                          help={
                                            PasswordsDoNotMatch
                                              ? "Password do not match."
                                              : !Longest
                                              ? "Minimum 8 characters, including uppercase, lowercase, special character and number."
                                              : ""
                                          }
                                        >
                                          <PasswordInput
                                            style={{
                                              width: "290px",
                                              borderRadius: "9px",
                                              placeItems: "center"
                                            }}
                                            value={""}
                                            label="Confirm New Password"
                                            placeholder="Password"
                                            onChange={onChangeNewPassword}
                                            labelStyles={{ zIndex: 1000 }}
                                            iconRender={visible =>
                                              visible ? (
                                                <EyeTwoToneStyled />
                                              ) : (
                                                <EyeInvisibleOutlined />
                                              )
                                            }
                                            textStyles={{ height: "46px" }}
                                          />
                                        </Item>
                                        {/* <Item
                                    style={{
                                      marginLeft: 17,
                                      marginTop: 7
                                    }}
                                  >
                                    {PleaseEnterPassword ? (
                                      <Tooltip title="Please enter password.">
                                        <InfoCircleOutlined
                                          style={{ color: "red", fontSize: 17 }}
                                        />
                                      </Tooltip>
                                    ) : (
                                      <>
                                        {PasswordsDoNotMatch && (
                                          <Tooltip title="Passwords do not match.">
                                            <InfoCircleOutlined
                                              style={{
                                                color: "red",
                                                fontSize: 17
                                              }}
                                            />
                                          </Tooltip>
                                        )}
                                      </>
                                    )}
                                  </Item> */}
                                      </Form>
                                    </FormWrapper>
                                  </div>

                                  <div>
                                    <Form.Item name="PAF">
                                      <Checkbox
                                        checked={checkPAF}
                                        onChange={PAFInput}
                                      >
                                        I have read and agree to the{" "}
                                        <a
                                          href="https://www.caregem.com/legal/provider/terms"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          style={{
                                            color: "blue",
                                            textDecoration: "none"
                                          }}
                                        >
                                          CareGem Terms and Conditions
                                        </a>{" "}
                                        &{" "}
                                        <a
                                          href="https://www.caregem.com/legal/provider/policy"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          style={{
                                            color: "blue",
                                            textDecoration: "none"
                                          }}
                                        >
                                          Privacy Policy
                                        </a>
                                      </Checkbox>
                                    </Form.Item>
                                    <Form.Item name="ETP">
                                      <Checkbox
                                        checked={checkETP}
                                        onChange={ETPInput}
                                      >
                                        I agree to receive SMS messages from
                                        CareGem and accept any applicable
                                        message and data rates.
                                      </Checkbox>
                                    </Form.Item>
                                  </div>

                                  <div
                                    style={{
                                      display: "flex",
                                      alignContent: "center",
                                      justifyContent: "center"
                                    }}
                                  >
                                    <Form.Item
                                      wrapperCol={{ offset: 0, span: 10 }}
                                    >
                                      <AppButton
                                        type={ButtonType.Primary}
                                        htmlType="submit"
                                        loading={isLoading}
                                        disabled={!checkETP || !checkPAF}
                                        buttonContent={
                                          <Space size={4}>
                                            {/* Get Started <ArrowRightOutlined /> */}
                                            Confirm
                                          </Space>
                                        }
                                        style={{
                                          width: "240px",
                                          marginBottom: "-40%"
                                        }}
                                        onClick={GetStartedClick}
                                      />
                                    </Form.Item>
                                  </div>
                                </AppCard>
                              </>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {/* FOR PATIENT WELCOME SCREEN */}
                        {showDisclaimer && userContext.fromConsent && (
                          <>
                            {isLoading ? (
                              <>
                                <div
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                  }}
                                >
                                  <Spin
                                    tip="Loading"
                                    size="large"
                                    style={{
                                      alignSelf: "center",
                                      marginTop: "45%"
                                    }}
                                  ></Spin>
                                </div>
                              </>
                            ) : (
                              <>
                                <AppCard
                                  cardHeight={"614px"}
                                  cardWidth={"1016px"}
                                  style={{
                                    position: "fixed",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)"
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between"
                                    }}
                                  >
                                    <img
                                      className="logo-left"
                                      src={clientLogoCaregem}
                                      alt="NephrologySpeaciallistsLogo"
                                      width={300}
                                    />
                                    <img
                                      className="logo-right"
                                      src={practiceLogoCaregem}
                                      alt="CareGemHealthLogo"
                                      width={180}
                                    />
                                  </div>

                                  <div
                                    style={{
                                      display: "flex",
                                      fontSize: 24,
                                      fontWeight: "bold",
                                      marginTop: 29,
                                      alignContent: "center",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      justifyItems: "center"
                                    }}
                                  >
                                    Welcome to CareGem.
                                  </div>

                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      marginTop: 29
                                    }}
                                  >
                                    <AppTextInput
                                      style={{
                                        width: "290px",
                                        borderRadius: "9px",
                                        placeItems: "center"
                                      }}
                                      value={
                                        consentData?.patient_details.first_name
                                      }
                                      label="First Name"
                                      labelStyles={{ zIndex: 1000 }}
                                      textStyles={{ height: "46px" }}
                                    />
                                    <AppTextInput
                                      style={{
                                        width: "290px",
                                        borderRadius: "9px",
                                        placeItems: "center"
                                      }}
                                      value={
                                        consentData?.patient_details.last_name
                                      }
                                      label="Last Name"
                                      labelStyles={{ zIndex: 1000 }}
                                      textStyles={{ height: "46px" }}
                                    />
                                    <AppTextInput
                                      style={{
                                        width: "290px",
                                        borderRadius: "9px",
                                        placeItems: "center"
                                      }}
                                      value={consent_username}
                                      label="Username"
                                      labelStyles={{ zIndex: 1000 }}
                                      textStyles={{ height: "46px" }}
                                    />
                                  </div>

                                  <div
                                    style={{
                                      fontSize: 18,
                                      fontWeight: "bold",
                                      marginTop: 34
                                    }}
                                  >
                                    Set your password here to get started
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      marginTop: 29
                                    }}
                                  >
                                    <FormWrapper>
                                      <Form
                                        name="new password form"
                                        form={form}
                                        style={{
                                          textAlign: "left",
                                          display: "flex",
                                          justifyContent: "space-between"
                                        }}
                                        onFinishFailed={onFinishFailed}
                                      >
                                        <Item
                                          name="newPassword"
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Please enter your new password."
                                            },
                                            {
                                              validator: validatePassword,
                                              validateTrigger: "submit"
                                            }
                                          ]}
                                          className="new-password"
                                        >
                                          <PasswordInput
                                            style={{
                                              width: "290px",
                                              borderRadius: "9px",
                                              placeItems: "center"
                                            }}
                                            value={""}
                                            label="Password"
                                            placeholder="Password"
                                            onChange={onChangeNewPassword}
                                            labelStyles={{ zIndex: 1000 }}
                                            iconRender={visible =>
                                              visible ? (
                                                <EyeTwoToneStyled />
                                              ) : (
                                                <EyeInvisibleOutlined />
                                              )
                                            }
                                            textStyles={{ height: "46px" }}
                                          />
                                        </Item>

                                        <Item
                                          style={{
                                            marginLeft: 8,
                                            marginRight: 10.5,
                                            marginTop: 7.5
                                          }}
                                        >
                                          <Tooltip title="Password should contain: Between 8 and 25 characters including one capital character, one lowercase character, one special character and one numeric character.">
                                            <InfoCircleOutlined
                                              style={{
                                                color: "gray",
                                                fontSize: 17
                                              }}
                                            />
                                          </Tooltip>
                                        </Item>

                                        <Item
                                          name="confirmNewPassword"
                                          style={{
                                            marginLeft: 23.5
                                          }}
                                          rules={[
                                            {
                                              validateTrigger: "submit",
                                              validator: validateConfirmPassword
                                            }
                                          ]}
                                          validateStatus={
                                            PasswordsDoNotMatch
                                              ? "error"
                                              : !Longest
                                              ? "error"
                                              : "success"
                                          }
                                          help={
                                            PasswordsDoNotMatch
                                              ? "Password do not match."
                                              : !Longest
                                              ? "Minimum 8 characters, including uppercase, lowercase, special character and number."
                                              : ""
                                          }
                                        >
                                          <PasswordInput
                                            style={{
                                              width: "290px",
                                              borderRadius: "9px",
                                              placeItems: "center"
                                            }}
                                            value={""}
                                            label="Confirm New Password"
                                            placeholder="Password"
                                            onChange={onChangeNewPassword}
                                            labelStyles={{ zIndex: 1000 }}
                                            iconRender={visible =>
                                              visible ? (
                                                <EyeTwoToneStyled />
                                              ) : (
                                                <EyeInvisibleOutlined />
                                              )
                                            }
                                            textStyles={{ height: "46px" }}
                                          />
                                        </Item>
                                        {/* <Item
                                          style={{
                                            marginLeft: 17,
                                            marginTop: 7
                                          }}
                                        >
                                          {PleaseEnterPassword ? (
                                            <Tooltip title="Please enter password.">
                                              <InfoCircleOutlined
                                                style={{
                                                  color: "red",
                                                  fontSize: 17
                                                }}
                                              />
                                            </Tooltip>
                                          ) : (
                                            <>
                                              {PasswordsDoNotMatch && (
                                                <Tooltip title="Passwords do not match.">
                                                  <InfoCircleOutlined
                                                    style={{
                                                      color: "red",
                                                      fontSize: 17
                                                    }}
                                                  />
                                                </Tooltip>
                                              )}
                                            </>
                                          )}
                                        </Item> */}
                                      </Form>
                                    </FormWrapper>
                                  </div>

                                  <div>
                                    <Form.Item name="PAF">
                                      <Checkbox
                                        checked={checkPAF}
                                        onChange={PAFInput}
                                      >
                                        I have read and agree to the{" "}
                                        <a
                                          onClick={handleCSA}
                                          style={{
                                            color: "blue",
                                            textDecoration: "none"
                                          }}
                                        >
                                          CareGem Subscriber Agreement,{" "}
                                        </a>
                                        <a
                                          href={
                                            consentData?.org_details
                                              ?.privacy_policy
                                          }
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          style={{
                                            color: "blue",
                                            textDecoration: "none"
                                          }}
                                        >
                                          Notice of HIPAA Privacy Practices,{" "}
                                        </a>
                                        <a
                                          href="https://www.caregem.com/legal/patient/terms"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          style={{
                                            color: "blue",
                                            textDecoration: "none"
                                          }}
                                        >
                                          Terms and Conditions
                                        </a>{" "}
                                        &{" "}
                                        <a
                                          href="https://www.caregem.com/legal/patient/policy"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          style={{
                                            color: "blue",
                                            textDecoration: "none"
                                          }}
                                        >
                                          Privacy Policy
                                        </a>
                                        .
                                      </Checkbox>
                                    </Form.Item>

                                    <Form.Item name="ETP">
                                      <Checkbox
                                        checked={checkETP}
                                        onChange={ETPInput}
                                      >
                                        I agree to receive SMS messages from
                                        CareGem and accept any applicable
                                        message and data rates.
                                      </Checkbox>
                                    </Form.Item>
                                  </div>

                                  <div
                                    style={{
                                      display: "flex",
                                      alignContent: "center",
                                      justifyContent: "center"
                                    }}
                                  >
                                    <Form.Item
                                      wrapperCol={{ offset: 0, span: 10 }}
                                    >
                                      <AppButton
                                        type={ButtonType.Primary}
                                        htmlType="submit"
                                        loading={isLoading}
                                        disabled={!checkETP || !checkPAF}
                                        buttonContent={
                                          <Space size={4}>
                                            {/* Get Started <ArrowRightOutlined /> */}
                                            Confirm
                                          </Space>
                                        }
                                        style={{
                                          width: "240px",
                                          marginBottom: "-40%"
                                        }}
                                        onClick={GetStartedClick}
                                      />
                                    </Form.Item>
                                  </div>
                                </AppCard>
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                </>
              ) : (
                <>
                  <Col span={24} style={{ textAlign: "center" }}>
                    <CareGemIcon logoHeight={55} logoWidth={63} />

                    <div
                      style={{ fontSize: "18px", fontWeight: 1000 }}
                      color={Colors.Black}
                    >
                      <b>CareGem</b>
                    </div>

                    <div
                      style={{
                        fontSize: "12px",
                        fontWeight: "bolder"
                      }}
                    >
                      <b>Login</b>
                    </div>
                  </Col>

                  <Col span={24}>
                    <FormWrapper>
                      <Form
                        name="login form"
                        initialValues={{
                          username: searchParams.get("username")
                            ? searchParams.get("username")
                            : sessionStorage.getItem("login_remember") ===
                              "true"
                            ? sessionStorage.getItem("login_username")
                            : username,
                          password: searchParams.get("code")
                            ? searchParams.get("code")
                            : password,
                          remember: remember
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                      >
                        <Item
                          name="username"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your username"
                            }
                          ]}
                        >
                          <AppTextInput
                            style={{
                              width: "240px",
                              borderRadius: "8px",
                              placeItems: "center"
                            }}
                            value={username}
                            label="Username"
                            placeholder="Username"
                            onChange={onInputChange}
                            labelStyles={{ zIndex: 1000 }}
                            textStyles={{ height: "46px" }}
                          />
                        </Item>

                        <Item
                          name="password"
                          className="new-password"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your password"
                            },
                            {
                              validator: validatePasswordLength,
                              validateTrigger: "submit"
                            }
                          ]}
                        >
                          <PasswordInput
                            style={{
                              width: "240px",
                              borderRadius: "8px",
                              placeItems: "center"
                            }}
                            value={password}
                            label="Password"
                            placeholder="Password"
                            onChange={onPasswordInput}
                            labelStyles={{ zIndex: 100 }}
                            iconRender={visible =>
                              visible ? (
                                <EyeTwoToneStyled />
                              ) : (
                                <EyeInvisibleOutlined />
                              )
                            }
                            textStyles={{ height: "46px" }}
                          />
                        </Item>

                        <Item
                          style={{
                            margin: "-16px 0px 20px 6px"
                          }}
                        >
                          <Link
                            to={CommonRoutesRef.ForgotPassword}
                            style={{
                              fontWeight: "bold",
                              color: `${Colors.Grey}`
                            }}
                          >
                            Forgot Password?
                          </Link>
                        </Item>

                        <Form.Item wrapperCol={{ offset: 0, span: 10 }}>
                          <AppButton
                            type={ButtonType.Primary}
                            htmlType="submit"
                            loading={isLoading}
                            buttonContent={<Space size={4}>Login</Space>}
                            style={{
                              width: "240px",
                              marginBottom: "-40%"
                            }}
                          />
                        </Form.Item>

                        <Form.Item
                          name="remember"
                          valuePropName="checked"
                          wrapperCol={{ offset: 6 }}
                        >
                          <Checkbox value={remember} onChange={onRememberInput}>
                            Remember me
                          </Checkbox>
                        </Form.Item>
                      </Form>
                    </FormWrapper>
                  </Col>
                </>
              )}
            </Row>
          </>
        )}
        <AppModal
          visible={CSAView}
          title={"CareGem Subscriber Agreement"}
          width={660}
          onCancel={handleCSA}
        >
          <CaregemAgreementForm
            pName={`${consentData?.patient_details?.first_name}  ${consentData?.patient_details?.last_name}`}
            pEmail={consentData?.patient_details?.email}
            clientLogo={consentData?.org_details?.logo}
          />
        </AppModal>
      </AppCard>
    </LoginBackground>
  );
};

interface FormWrapperProps {
  setNewPassword?: boolean;
}

const FormWrapper = styled.div<FormWrapperProps>`
  display: grid;
  place-items: center;
  .new-password {
    .ant-form-item-explain-error {
      font-size: ${props => (props.setNewPassword ? "11px" : "inherit")};
      max-width: 250px;
      margin-bottom: 10px;
    }
  }
`;

const LoginBackground = styled.div`
  width: 100vw;
  height: 100vh;
  background: linear-gradient(173.57deg, #06bfe3 0%, #208cff 100%);
  box-shadow: 0 3px 8px 0 rgba(6, 157, 222, 0.5);
`;

const BackArrowContainer = styled.div`
  position: absolute;
  top: 10;
  left: 10;
  color: ${Colors.Grey};
  cursor: pointer;
  font-size: 15px;
  z-index: 10;
`;

const CustomCheckBox = styled(Checkbox)`
  color: ${Colors.DodgerBlue};
`;

const Disclaimer = styled.div`
  color: ${Colors.Grey};
  margin-top: 10px;
  text-align: justify;
  font-size: 12px;
`;

export default LoginPage;
