import React, { useState } from "react";
import { useQuery } from "react-query";
import { createApiClient } from "apiClient";
import { AppTabs } from "components/tabs";
import { useDebounce } from "helpers/customHooks";
import { AllProvidersTable } from "./allProvidersTable";
import { ProvidersList } from "sharedTypes";

const providerTypes = {
  physicians: "physicians",
  nurses: "nurses",
  caseManagers: "case managers",
  socialWorkers: "social workers",
  dieticians: "dieticians",
  medicalAssistants: "medical assistants",
  all: "all"
};

interface ProviderTabsProps {
  searchSpecialtyValue: string;
  searchNameValue: string;
}

export const ProvidersTabs: React.FC<ProviderTabsProps> = ({
  searchNameValue,
  searchSpecialtyValue
}) => {
  const [selectedTab, setSelectedTab] = useState("physicians");
  const getSelectedTab = (key: string) => {
    setSelectedTab(key);
  };
  const debouncedNameValue = useDebounce(searchNameValue);
  const debouncedSpecialtyValue = useDebounce(searchSpecialtyValue);

  const [enableSideBarMenu, setEnableSideBarMenu] = useState<any>(() => {
    const storedValue = localStorage?.getItem("disableComponent");
    return storedValue ? JSON.parse(storedValue) : {}; // Provide a default empty object if value is null or undefined
  });

  const {
    isLoading: isLoadingPhysiciansList,
    data: physiciansData,
    error: isPhysiciansListError,
    isFetching: isPhysiciansListRefetchLoading
  } = useQuery<ProvidersList[]>(
    ["all-physicians-list", debouncedNameValue, debouncedSpecialtyValue],
    async () => {
      return await createApiClient().getAllPhysiciansList(
        searchNameValue,
        searchSpecialtyValue,
        true
      );
    }
  );

  const {
    isLoading: isLoadingCaseManagersList,
    data: caseManagersData,
    error: isCaseManagersError,
    isFetching: isCaseManagersListRefetchLoading
  } = useQuery<ProvidersList[]>(
    ["all-case-managers-list", debouncedNameValue, debouncedSpecialtyValue],
    async () => {
      return await createApiClient().getAllCaseManagersList(
        searchNameValue,
        searchSpecialtyValue,
        true
      );
    }
  );

  const {
    isLoading: isLoadingNursesList,
    data: nursesData,
    error: isNursesError,
    isFetching: isNursesListRefetchLoading
  } = useQuery<ProvidersList[]>(
    ["all-nurses-list", debouncedNameValue, debouncedSpecialtyValue],
    async () => {
      return await createApiClient().getAllNursesList(
        searchNameValue,
        searchSpecialtyValue,
        true
      );
    }
  );

  const tabsContentOne = [
    {
      key: providerTypes.physicians,
      tabName: "Physicians"
    },
    {
      key: providerTypes.caseManagers,
      tabName: "Case Managers"
    },
    {
      key: providerTypes.nurses,
      tabName: "Nurses"
    },
    {
      key: providerTypes.socialWorkers,
      tabName: "Social Workers"
    },
    {
      key: providerTypes.dieticians,
      tabName: "Dieticians"
    },
    {
      key: providerTypes.medicalAssistants,
      tabName: "Medical Assistants"
    },
    {
      key: providerTypes.all,
      tabName: "All"
    }
  ];

  const tabsContentTwo = [
    {
      key: providerTypes.physicians,
      tabName: "Physicians"
    },
    {
      key: providerTypes.caseManagers,
      tabName: "Case Managers"
    },
    {
      key: providerTypes.nurses,
      tabName: "Medical Assistants"
    },
    {
      key: providerTypes.all,
      tabName: "All"
    }
  ];

  const filteredPhysicianData = physiciansData?.filter(
    (v, i, a) => a.findIndex(v2 => v.id === v2.id) === i
  );

  const filteredNursesData = nursesData?.filter(
    (v, i, a) => a.findIndex(v2 => v.id === v2.id) === i
  );

  const filteredCaseManagersData = caseManagersData?.filter(
    (v, i, a) => a.findIndex(v2 => v.id === v2.id) === i
  );

  return (
    <>
      <div>
        <AppTabs
          defaultActiveKey={selectedTab}
          tabsContent={
            enableSideBarMenu?.vlan_base_care_plan !== 1
              ? tabsContentOne
              : tabsContentTwo
          }
          tabPosition="top"
          getSelectedTab={getSelectedTab}
        />
      </div>
      <AllProvidersTable
        data={
          selectedTab === providerTypes.physicians
            ? filteredPhysicianData
            : selectedTab === providerTypes.caseManagers
            ? filteredCaseManagersData
            : selectedTab === providerTypes.nurses
            ? filteredNursesData
            : selectedTab === providerTypes.all
            ? [
                ...(physiciansData || []),
                ...(caseManagersData || []),
                ...(nursesData || [])
              ]
            : []
        }
        loading={
          selectedTab === providerTypes.physicians
            ? isLoadingPhysiciansList || isPhysiciansListRefetchLoading
            : selectedTab === providerTypes.caseManagers
            ? isLoadingCaseManagersList || isCaseManagersListRefetchLoading
            : selectedTab === providerTypes.nurses
            ? isLoadingNursesList || isNursesListRefetchLoading
            : false
        }
        role={selectedTab}
        searchNameValue={searchNameValue}
        searchSpecialtyValue={searchSpecialtyValue}
        selectedTab={selectedTab}
        error={
          selectedTab === providerTypes.physicians
            ? isPhysiciansListError
            : selectedTab === providerTypes.caseManagers
            ? isCaseManagersError
            : isNursesError
        }
      />
    </>
  );
};
