import React, { useEffect, useState } from "react";
import Menu from "antd/lib/menu";
import Checkbox from "antd/lib/checkbox";
import Space from "antd/lib/space";
import styled from "styled-components";
import { FixedSizeList as List } from "react-window"; // Import react-window
import { Colors } from "helpers/colors";
import { CheckboxMenuCommonInterface } from "sharedTypes";

interface CheckboxMenuProps {
  readonly applied?: boolean;
  readonly setApplied: (applied: boolean) => void;
  readonly currentItems: CheckboxMenuCommonInterface[];
  readonly setFinalItems: (checkedItems: CheckboxMenuCommonInterface[]) => void;
  readonly isDiagnoses?: boolean;
}

export const BillCheckboxMenu: React.FC<CheckboxMenuProps> = ({
  applied,
  setApplied,
  isDiagnoses,
  setFinalItems,
  currentItems
}) => {
  const [checkedItems, setCheckedItems] =
    useState<CheckboxMenuCommonInterface[]>(currentItems);

  // Update checkedItems when currentItems changes
  useEffect(() => {
    setCheckedItems(currentItems);
  }, [currentItems]);

  useEffect(() => {
    if (applied) {
      setFinalItems(checkedItems);
      setApplied(false);
    }
  }, [applied, checkedItems, setFinalItems, setApplied]);

  const onSelectMenuItem = (item: string) => {
    setApplied(false);

    const checkedTemp = [...checkedItems];

    const objIndex = checkedItems.findIndex(obj => obj.code === item);

    checkedTemp[objIndex].checked = !checkedTemp[objIndex].checked;

    setCheckedItems(checkedTemp);
  };

  const renderRow = ({
    index,
    style
  }: {
    index: number;
    style: React.CSSProperties;
  }) => {
    const { code, desc, checked } = checkedItems[index];

    return (
      <div style={style} key={index}>
        <Menu.Item
          onClick={() => {
            onSelectMenuItem(code);
          }}
        >
          <Space size={10}>
            <CheckboxStyled checked={checked} />
            {checked ? (
              <>
                {isDiagnoses ? (
                  <b>
                    {desc} ({code})
                  </b>
                ) : (
                  <b>{`${code} - ${desc}`}</b>
                )}
              </>
            ) : (
              <>
                {isDiagnoses ? (
                  <>
                    {desc} ({code})
                  </>
                ) : (
                  `${code} - ${desc}`
                )}
              </>
            )}
          </Space>
        </Menu.Item>
      </div>
    );
  };

  return (
    <MenuStyled>
      {checkedItems.length > 0 ? (
        <List
          height={200}
          itemCount={checkedItems.length}
          itemSize={32}
          width={600}
        >
          {renderRow}
        </List>
      ) : (
        <div
          style={{
            height: 200,
            width: 600,
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          NO DATA
        </div>
      )}
    </MenuStyled>
  );
};

const CheckboxStyled = styled(Checkbox)`
  pointer-events: none;
`;

const MenuStyled = styled(Menu)`
  width: fit-content;
  height: 220px;
  overflow: hidden; /* Hidden scroll as react-window handles it */
  padding: 15px 6px 12px 6px;
  color: ${Colors.Grey};
  border-right: none;
  background-color: ${Colors.GhostWhite};

  .ant-menu-item {
    margin-top: -5px !important;
    :hover {
      background-color: ${Colors.BabyBlue};
      border-radius: 10px;
      transition: 0s;
      color: ${Colors.Black};
    }
  }

  .ant-menu-item-selected {
    background-color: ${Colors.BabyBlue} !important;
    border-radius: 10px;
    transition: 0s;
    color: ${Colors.Black};
  }
`;
